<template>
  <div class="science-policy-detail">
    <div class="spd-root">
      <div class="spd-main">
        <div class="spd-title ellipse-1">
          {{ detailInfo && detailInfo.title }}
        </div>
        <div class="spd-str">

          <span v-if="detailInfo&&detailInfo.policySource">政策来源：{{ detailInfo && detailInfo.policySource }}</span>
          <span  :class="{ 'margin-text': detailInfo&&detailInfo.policySource }">地区分类：{{ detailInfo && detailInfo.provinceCode }}-{{ detailInfo && detailInfo.cityCode }}{{ detailInfo && detailInfo.areaCode }}</span>
        </div>

        <div class="spd-time">添加时间：{{ detailInfo && detailInfo.createdTime }}</div>

        <div class="spd-body" v-html="detailInfo&&detailInfo.content"></div>
        <div class="spd-bottom">
          <div class="spd-bottom-tag" style="position: relative">
            <div class="spd-bottom-tag-name">标签：</div>
            <div class="spd-bottom-tag-circle" v-for="(item,index) in labelList" :key="index">{{ item }}</div>
            <div @click="downloadFile(detailInfo.articleAttachments[0].url)"
                 style="position: absolute;right: 0;cursor: pointer"
                 v-if="detailInfo.articleAttachments && detailInfo.articleAttachments[0].url"><img :src="download"
                                                                                                   alt=""
                                                                                                   style="vertical-align: text-top"><span
              style="text-decoration: none;color: rgb(102, 102, 102)">下载</span></div>
          </div>
          <div class="spd-bottom-click">
            <div class="ellipse-1" @click="getDetail(preDetailInfo&&preDetailInfo.id)">
              上一篇：{{ preDetailInfo && preDetailInfo.title }}
            </div>
            <div class="ellipse-1" @click="getDetail(nextDetailInfo&&nextDetailInfo.id)">
              下一篇：{{ nextDetailInfo && nextDetailInfo.title }}
            </div>
          </div>
        </div>

      </div>

      <div class="spd-right">
        <patent-component />
      </div>
    </div>

  </div>
</template>

<script>

import newestPatentAndAchievement from "./newestPatentAndAchievement";
import { getSciencePolicyDetailById } from "../../plugins/api/sciencePolicyApi";
import download from "@/assets/image/index/download.png";
import axios from "axios";
import { patentUrl } from "../../plugins/http/baseUrl";
import { getLocalToken } from "../../plugins/tools/storage";

export default {
  name: "sciencePolicyDetail",
  data() {
    return {
      download: download,
      detailInfo: {},
      preDetailInfo: {},
      nextDetailInfo: {},
      labelList: []
    };
  },
  components: {
    patentComponent: newestPatentAndAchievement
  },
  mounted() {
    this.getDetail(this.$route.query.id);
  },
  methods: {
    async downloadFile(url) {
      let fileName = url;
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function(e) {
        //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
        var url = window.URL.createObjectURL(x.response);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    },
    async getDetail(id) {
      const params = {
        id: id
      };
      const json = await getSciencePolicyDetailById(params);
      if (json && json.code === 0 && json.result) {
        if (json.result.nowArticleVo) {
          scrollTo(0, 0);
          this.detailInfo = json.result.nowArticleVo;
          this.labelList = json.result.nowArticleVo.label.split(";");
        }
        if (json.result.preArticleVo) {
          this.preDetailInfo = json.result.preArticleVo;
        }
        if (json.result.nextArticleVo) {
          this.nextDetailInfo = json.result.nextArticleVo;
        }
      }
    }
  }

};
</script>

<style scoped lang="css">

.margin-text {
  margin-left: 50px;
}

.science-policy-detail {
  background-color: #FAFAFA;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
}

.spd-root {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.spd-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  padding: 30px 40px;
  width: 840px;
  height: fit-content;
}

.spd-title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.spd-str {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.spd-str span {
  font-size: 14px;
  font-weight: 400;
  color: #333333;

}

.spd-time {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-top: 15px;
  width: 100%;
}

.spd-body {
  margin-top: 15px;
}

.spd-body >>> img {
  display: block;
  margin: 0 auto;
  max-width: 100% !important;
  height: auto !important;
}

.spd-bottom {
  border-top: 1px #E8E8E8 solid;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.spd-bottom-tag {
  display: flex;
  flex-direction: row;
  padding-top: 35px;
  flex-wrap: wrap;
  align-items: center;
}

.spd-bottom-tag-name {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.spd-bottom-tag-circle {
  padding: 5px;
  border: 1px solid #DCDCDC;
  border-radius: 13px;
  margin-left: 10px;
}

.spd-bottom-click {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 40px;
}

.spd-bottom-click div {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  flex: 0.45;
  cursor: pointer;
}

.spd-right {
  background-color: #FFFFFF;
  margin-left: 30px;
  height: fit-content;
}
</style>
